<template>
  <div class="job-ad-owner-menu">
    <div class="job-ad-owner-menu__headline">
      <strong>YOUR JOB AD SETTINGS</strong>
    </div>

    <div class="job-ad-owner-menu__state">
      <strong>
        <span v-if="approved">
          <span v-if="state === 'published'">
            Your job ad is live
          </span>
          <span v-if="state === 'draft'">
            You job has been approved, you can publish it at any time.
          </span>
          <span v-if="state === 'expired'">
            You job ad is paused, you can re-publish it at any time.
          </span>
        </span>
        <span v-else>
          <span v-if="state === 'published'">
            Your job ad will be live after a quick approval.
          </span>
        </span>
      </strong>
    </div>

    <div
      v-if="showPublishButton"
      class="job-ad-owner-menu__item">
      <a
        class="btn btn--sm"
        :class="{
          'btn--disabled js-disabled-cta': !publishable
        }"
        href="#"
        @click.prevent="publish">
        Publish
      </a>
      <div
        v-if="publishable"
        class="job-ad-owner-menu__item-hint">
        Publish this job ad for free forever.
      </div>
      <div
        v-else
        class="job-ad-owner-menu__item-hint">
        You need to fill in all the required fields before publishing this job ad.
      </div>
    </div>

    <div
      v-if="showPauseButton"
      class="job-ad-owner-menu__item">
      <a
        class="btn btn--sm"
        href="#"
        @click.prevent="pause">
        Pause
      </a>
      <div
        class="job-ad-owner-menu__item-hint">
        You will be able to re-publish this job ad later.
      </div>
    </div>

    <div class="job-ad-owner-menu__item">
      <a
        class="btn btn--sm"
        :href="'mailto:hello@sqlhabit.com?subject=Job ad promotion'">
        Promote
      </a>
      <div
        class="job-ad-owner-menu__item-hint">
        Buy newsletter feature and unlimited course ads for $199.
      </div>
    </div>

    <div class="job-ad-owner-menu__item">
      <a
        class="btn btn--sm"
        :href="editUrl">
        Edit
      </a>
    </div>

    <div class="job-ad-owner-menu__support-hint">
      Have questions about your job ad? Reach out to <a href="mailto:support@sqlhabit.com">support@sqlhabit.com</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";

export default {
  props: {
    slug: String,
    state: String,
    publishable: {
      type: Boolean,
      default: false
    },
    purchaseUrl: String,
    approved: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editUrl() {
      return `/job-board/jobs/${this.slug}/edit`;
    },
    publishUrl() {
      return `/api/job_board/jobs/${this.slug}/publish`;
    },
    showPublishButton() {
      return this.state !== "published";
    },
    showPauseButton() {
      return this.state === "published";
    }
  },
  methods: {
    publish() {
      let formData = new FormData();
      const publishUrl = `/api/job_board/jobs/${this.slug}/publish`;

      formData.append(
        "slug",
        this.slug
      );

      axios.patch(publishUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        const jobAd = response.data.job_ad;

        if(jobAd.live) {
          notify({
            message: "Congratz, your job ad is live!"
          });
        } else {
          notify({
            message: "Your job ad will be live after a quick review"
          });
        }

        window.location.reload();
      }).catch(function (error) {
        notify({
          message: "Hmmm, something went wrong. Please, contact support at support@sqlhabit.com",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    },
    pause() {
      let formData = new FormData();
      const publishUrl = `/api/job_board/jobs/${this.slug}/pause`;

      formData.append(
        "slug",
        this.slug
      );

      axios.patch(publishUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Your job ad has been successfully paused."
        });

        window.location.reload();
      }).catch(function (error) {
        notify({
          message: "Hmmm, something went wrong. Please, contact support at support@sqlhabit.com",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_breakpoint";
@import "design_system/_colors";
@import "design_system/_grid";
@import "design_system/_spacing";
@import "design_system/_typography";

.job-ad-owner-menu {
  padding: $px16;

  border-radius: $px8;
  box-shadow: 0 0 $px8 $grey-9;

  background-color: $yellow-10;

  margin-bottom: $px32;

  &__headline {
    margin-bottom: $px16;
  }

  &__state {
    margin-bottom: $px24;

    @include font-small;
    color: $grey-3;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $px8;
    }
  }

  &__item-hint {
    margin-left: $px8;

    @include font-small;
    color: $grey-5;
  }

  &__support-hint {
    margin-top: $px16;

    @include font-small;
    color: $grey-5;
  }
}
</style>
