<template>
  <div class="practice-active-menu-items">
    <div class="practice-active-menu-items__items">
      <div
        class="practice-active-menu-items__item"
        v-if="customStore.state.searchQuery"
        @click.prevent="customStore.clearSearch()">
        {{ customStore.state.searchQuery }}

        <CloseIcon
          class="practice-active-menu-items__item-close-icon"/>
      </div>

      <div
        class="practice-active-menu-items__item"
        :key="filter"
        v-for="(name, filter) in customStore.state.selectedFilters"
        @click.prevent="removeFilter(filter)">
        {{ name }}

        <CloseIcon
          class="practice-active-menu-items__item-close-icon"/>
      </div>
    </div>

    <a
      href="#"
      class="practice-active-menu-items__reset-link"
      v-if="showResetLink"
      @click.prevent="customStore.reset()">
      Reset all filters
    </a>
  </div>
</template>

<script>
import CloseIcon from "font_awesome/times-circle-light.svg";
import customStore from "../custom-store";

export default {
  name: "PracticeActiveMenuItems",
  components: {
    CloseIcon
  },
  data() {
    return {
      customStore: customStore
    }
  },
  computed: {
    showResetLink() {
      return this.customStore.state.searchQuery || Object.keys(this.customStore.state.selectedFilters).length > 0;
    }
  },
  methods: {
    removeFilter(filter) {
      this.customStore.removeFilter(this, filter);
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-active-menu-items {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: $px8;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $px4 $px8;

    border-radius: $px12;

    background-color: $grey-9;

    @include font-small;

    &:hover {
      cursor: pointer;

      #{$root}__item-close-icon {
        color: $grey-6;
      }
    }
  }

  &__reset-link {
    flex-shrink: 0;
    margin-left: $px16;

    @include font-small;
  }

  &__item-close-icon {
    margin-left: $px4;
    width: $px16;
    height: $px16;
  }
}
</style>
