import Vue from "vue/dist/vue.esm"
import VueI18n from "vue-i18n";
import BootcampList from "components/bootcamp/bootcamp-list.vue"
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";
import { store } from "store";

setCouponCookie();

const $container = document.querySelector(".js-bootcamp-seats-calculator");

if($container) {
  const couponCode = $container.dataset.couponCode;
  const bootcampName = $container.dataset.bootcampName;

  const bootcampsPromise = axios.get("/api/bootcamps.json", {
    params: {
      name: bootcampName
    }
  })

  const pricesPromise = axios.get("/api/prices.json", {
    params: {
      slugs: ["bootcamp_seat"],
      coupon_code: couponCode
    }
  })

  Promise.all([bootcampsPromise, pricesPromise])
    .then(([bootcampsResponse, pricesResponse]) => {
      const bootcamps = bootcampsResponse.data.bootcamps;
      const products = pricesResponse.data.products;
      const bootcampProduct = products.find(p => p.slug === "bootcamp_seat");

      new Vue({
        el: $container,
        store,
        i18n: new VueI18n({
          locale: "en",
          silentTranslationWarn: process.env.NODE_ENV === "production"
        }),
        render: h => h(BootcampList, {
          props: {
            bootcamps,
            product: bootcampProduct
          }
        })
      });
    })
  .catch(function(error) {
    Sentry.captureException(error);
  });
}
