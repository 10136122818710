<template>
  <div class="thumbs-rating">
    <div class="thumbs-rating__question">
      How do you like this {{ contentItemName }}?
    </div>

    <a href="#" class="thumbs-rating__rating-button" @click.prevent="rate(1)">
      <div class="thumbs-rating__rating-button-body thumbs-rating__rating-button-body--thumbs-up">
        <ThumbsUpIcon :class="'thumbs-rating__rating-icon'" />
      </div>
    </a>

    <a href="#" class="thumbs-rating__rating-button" @click.prevent="rate(-1)">
      <div class="thumbs-rating__rating-button-body thumbs-rating__rating-button-body--thumbs-down">
        <ThumbsDownIcon :class="'thumbs-rating__rating-icon'" />
      </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import ThumbsUpIcon from "font_awesome/thumbs-up-light.svg";
import ThumbsDownIcon from "font_awesome/thumbs-down-light.svg";

export default {
  computed: {
    contentItemName() {
      if(this.contentItem.contentType === "MockInterviews::Exercise") {
        return "exercise";
      } else {
        return this.contentItem.contentType;
      }
    }
  },
  methods: {
    rate: function(value) {
      let formData = new FormData();

      formData.append(
        "content_item_rating[value]",
        value
      );

      formData.append(
        "content_item_rating[content_id]",
        this.contentItem.contentId
      );

      formData.append(
        "content_item_rating[content_type]",
        this.contentItem.contentType
      );

      axios.post(this.rating.createUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Thank you for the rating!"
        });

        const contentItemRating = response.data.rating;

        this.$emit("thumbsRatingSaved", contentItemRating.id, value);
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  },
  props: {
    contentItem: Object,
    rating: Object
  },
  components: {
    ThumbsUpIcon,
    ThumbsDownIcon
  }
}
</script>

<style lang="scss">
  @import "design_system/_breakpoint";
  @import "design_system/_colors";
  @import "design_system/_grid";
  @import "design_system/_spacing";

  .thumbs-rating {
    display: flex;
    align-items: center;

    &__question {
      margin-right: $px8;
    }

    &__rating-button {
      &:not(:last-child) {
        margin-right: $px16;
      }
    }

    &__rating-button-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $px48;
      height: $px48;

      border: $px1 solid $grey-7;
      border-radius: $px24;

      &--thumbs-up {
        padding-bottom: $px4;
      }

      &--thumbs-down {
        padding-top: $px4;
      }
    }

    &__rating-icon {
      width: $px24;
      height: $px24;
    }
  }
</style>
