import axios from "axios";
import { notify } from "modules/notify";

document.addEventListener("click", e => {
  if(e.target.classList.contains("js-report-forum-spam")) {
    e.preventDefault();

    if(window.state && !window.state.loggedIn) {
      return;
    }

    const {
      spammableId,
      spammableType
    } = e.target.dataset;

    let formData = new FormData();
        formData.append("forum_spam_report[spammable_id]", spammableId);
        formData.append("forum_spam_report[spammable_type]", spammableType);

    axios.post("/forum/spam_reports.json", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then(response => {
      notify({
        message: "Spam report was created, thank you very much!"
      });
    }).catch(function (error) {
      console.warn(error);

      notify({
        message: "Smth went wrong",
        type: "alert"
      });

      Sentry.captureException(error);
    });
  }
});
