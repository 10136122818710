import axios from "axios";
import { getCookie, setCookie } from "modules/cookie";
import { trackEventFromCookie, trackGTMEvent } from "modules/gtm";
import loadPartials from "modules/load-partials";
import handleCustomerAndFreeContentRedirects from "modules/handle-customer-and-free-content-redirects";
import handleLoggedInRedirects from "modules/handle-logged-in-redirects";
import updateFullContentLinks from "modules/update-full-content-links";
import { toggleLoggedInElements, toggleLoggedOutElements, toggleCustomerElements } from "modules/toggle-auth-dependent-elemenets";
import { store } from "store";

const generateRandomId = (length) => {
  let result           = "";
  let characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;

  for(let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const createUUID = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });

  return uuid;
};

const getFingerprint = () => {
  const FINGERPRINT_COOKIE = "sqlhfp";

  let fingerprint = getCookie(FINGERPRINT_COOKIE);

  if(!fingerprint) {
    fingerprint = createUUID();
  }

  setCookie(FINGERPRINT_COOKIE, fingerprint, 365);

  return fingerprint;
};

const getScreenResolution = () => {
  return `${window.screen.width}x${window.screen.height}`;
};

export const checkIn = (customVariables = {}) => {
  trackGTMEvent({
    event: "pageview"
  });

  let formData = new FormData();

  formData.set(
    "check_in[r_u]",
    document.referrer
  );

  formData.set(
    "check_in[fp]",
    getFingerprint()
  );

  formData.set(
    "check_in[s_r]",
    getScreenResolution()
  );

  formData.set(
    "check_in[pv_id]",
    generateRandomId(8)
  );

  if(Object.keys(customVariables).length > 0) {
    formData.set(
      "check_in[c_v]",
      JSON.stringify(customVariables)
    );
  }

  const formDataQueryString = new URLSearchParams(formData);

  axios.get("/api/check-in.json", {
    params: formDataQueryString
  }).then(response => {
    const params = response.data;

    handleLoggedInRedirects(params.guest);
    handleCustomerAndFreeContentRedirects(params.segment);

    store.dispatch("user/authenticate", params);

    let csrfParamTag = document.querySelector("meta[name=csrf-param]");
    if(!csrfParamTag) {
      csrfParamTag = document.createElement("meta");
      csrfParamTag.name = "csrf-param";
      csrfParamTag.content = "authenticity_token";
      document.head.appendChild(csrfParamTag);
    }
    csrfParamTag.content = "authenticity_token";

    let csrfMetaTag = document.querySelector("meta[name=csrf-token]");
    if(!csrfMetaTag) {
      csrfMetaTag = document.createElement("meta");
      csrfMetaTag.name = "csrf-token";
      csrfMetaTag.content = params.authToken;
      document.head.appendChild(csrfMetaTag);
    }
    csrfMetaTag.content = params.authToken;

    if (!("state" in window)) {
      window.state = {};
    }

    window.state.loggedIn = !params.guest;
    window.state.authToken = params.authToken;
    window.state.username = params.username;

    toggleLoggedInElements(params.guest);
    toggleLoggedOutElements(params.guest);
    toggleCustomerElements(params.segment);
    loadPartials({ userSegment: params.segment });
    trackEventFromCookie(); // In case sqlhe was set via the /check-in endpoint.
    updateFullContentLinks(params.segment);
  }).catch(error => {
    Sentry.captureException(error);
  });
};
