import axios from "axios";
import { getCookie, setCookie } from "modules/cookie";
import { loadGTM } from "modules/gtm";

const COOKIE_NAME = "cookie-consent";

export const recordCookieConsent = () => {
  let formData = new FormData();

  formData.set("cc", getCookie(COOKIE_NAME) === "true");

  axios.post("/api/cc.json", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
    }
  }).catch(error => {
    Sentry.captureException(error);
  });
};

export const handleCookieConsent = (consentAccepted) => {
  setCookie(COOKIE_NAME, consentAccepted, 365);

  recordCookieConsent();

  if(consentAccepted) {
    loadGTM();
  }
};
