<template>
  <div class="search-field">
    <div class="search-field__container">
      <SearchIcon
        class="search-field__search-icon" />

      <input
        class="search-field__input"
        type="text"
        ref="input"
        v-model="query"
        placeholder="Search SQL keywords, metrics, etc">

      <CloseIcon
        class="search-field__close-icon"
        @click.prevent="$emit('close')" />
    </div>
  </div>
</template>

<script>
import SearchIcon from "font_awesome/search-light.svg";
import CloseIcon from "font_awesome/x-light.svg";
import debounce from "lodash.debounce";

export default {
  name: "SearchField",
  components: {
    SearchIcon,
    CloseIcon
  },
  props: {
    searchIndex: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      query: ""
    }
  },
  watch: {
    query: debounce(function(newQuery) {
      if(!newQuery) {
        this.$emit("searchResults", []);
      }

      if(newQuery.length < 3) {
        return;
      }

      this.searchIndex.search(newQuery, {
        userToken: window.userSlug,
        filters: `published?:true`
      }).then(({ hits }) => {
        this.$emit("searchResults", hits, newQuery);
      });
    }, 500)
  },
  mounted() {
    this.$refs.input.focus();
  }
}
</script>

<style lang="scss">
@import "design_system/_colors";
@import "design_system/_spacing";
@import "design_system/_grid";
@import "design_system/_typography";
@import "design_system/_animations";

.search-field {
  padding: 0 $px16;

  border-radius: $px8;

  background-color: $grey-10;

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
    padding: $px16 $px16;

    border: 0;
    outline: none;

    @include font-large;

    background-color: $grey-10;
  }

  &__search-icon {
    width: $px24;
    height: $px24;

    color: $grey-5;

    cursor: pointer;
  }

  &__close-icon {
    width: $px24;
    height: $px24;

    color: $grey-5;

    cursor: pointer;

    &:hover {
      color: $grey-4;

      transform: scale(1.05);
    }
  }
}
</style>
