<template>
  <div class="practice-search-menu-item">
    <input
      class="practice-search-menu-item__input"
      type="text"
      placeholder="Search"
      v-model="customStore.state.searchQuery"
      @keydown="customStore.closeMenus()" >
  </div>
</template>

<script>
import customStore from "../custom-store";

export default {
  name: "PracticeSearchMenuItem",
  data() {
    return {
      customStore: customStore
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-search-menu-item {
  width: 100%;

  &__input {
    width: 100%;
    margin-bottom: $px8;
    padding: 9px $px16;

    border: $px1 solid $grey-8;
    border-radius: $px4;

    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: $px1 solid $grey-7;
    }
  }
}
</style>
