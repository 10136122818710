import axios from "axios";
import { notify } from "modules/notify";

document.addEventListener("click", e => {
  if(e.target && e.target.matches(".js-star-query")) {
    e.preventDefault();

    const $starLink = e.target;

    let formData = new FormData();

    formData.set("body", $starLink.dataset.queryBody);

    axios.post("/api/queries/star.json", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then(response => {
      let notificationMessage = "Query was starred successfully!";

      notify({
        message: notificationMessage
      });
    }).catch(function(error) {
      notify({
        message: "Something went wrong",
        type: "alert"
      });

      Sentry.captureException(error);
    }).finally(function() {
    });
  }
});
