<template>
  <div :class="componentClass">
    <div class="page-feedback__cta" v-if="!formVisible" @click="toggleForm">
      <EnvelopeIcon :class="'page-feedback__envelope-icon'" />
    </div>

    <div class="page-feedback__feedback" v-else>
      <div class="page-feedback__header" @click="toggleForm">
        <div class="page-feedback__header-copy">
          Ask anything or give feedback
        </div>

        <DownArrowIcon :class="'page-feedback__close-icon'" />
      </div>

      <form action="#" class="page-feedback__form" @submit.prevent="submitFeedback">
        <input type="text" class="page-feedback__name" placeholder="Your name" v-model="name" />

        <input type="email" class="page-feedback__email" placeholder="Your email" v-model="email" required="true" v-if="!userLoggedIn" />

        <textarea name="" class="page-feedback__text-area" v-model="feedback" placeholder="Message" required="true"></textarea>

        <input type="submit" class="page-feedback__submit" value="Submit feedback" />
      </form>
    </div>
  </div>
</template>

<script>
import EnvelopeIcon from "font_awesome/envelope-light.svg";
import DownArrowIcon from "font_awesome/angle-down-light.svg";
import axios from "axios";
import { notify } from "modules/notify";
import { getCookie, setCookie } from "modules/cookie";

export default {
  name: "PageFeedback",
  components: {
    EnvelopeIcon,
    DownArrowIcon
  },
  props: {
    size: {
      type: String,
      default: "normal"
    }
  },
  data() {
    return {
      formVisible: false,
      name: "",
      email: window.userEmail,
      feedback: "",
      userLoggedIn: window.userEmail
    }
  },
  computed: {
    componentClass() {
      let cssClass = this.formVisible ? "page-feedback page-feedback--sticky" : "page-feedback";

      if(this.size === "small") {
        cssClass += " page-feedback--small";
      }

      return cssClass;
    }
  },
  methods: {
    toggleForm() {
      this.formVisible = !this.formVisible;

      if(this.formVisible && getCookie("feedback-form-name")) {
        this.name = getCookie("feedback-form-name");
      }
    },
    clearForm() {
      this.feedback = "";
    },
    submitFeedback() {
      let formData = new FormData();

      formData.append(
        "page_feedback[feedback]",
        `<strong>Text fragment</strong>\r\n${this.feedback}`
      );

      formData.append(
        "page_feedback[email]",
        this.email
      );

      formData.append(
        "page_feedback[name]",
        this.name
      );

      axios.post("/api/page_feedbacks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Thank you for the feedback!"
        });

        this.toggleForm();

        setCookie("feedback-form-name", this.name, 365);

        this.clearForm();
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.page-feedback {
  $root: &;
  position: fixed;
  bottom: $px24;
  right: $px24;
  z-index: 2147483648;

  &--sticky {
    bottom: 0%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $px8;

    border-radius: $px8 $px8 0 0;

    background-color: $orange-7;

    color: $white;

    cursor: pointer;

    &:hover {
      #{$root}__close-icon {
        transform: scale(1.5);
      }
    }
  }

  &__cta {
    display: flex;
    padding: $px16 $px24;

    border-radius: $px24;

    background-color: $orange-7;

    box-shadow: 0 $px4 $px16 0 $grey-8;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__feedback {
    border-radius: $px8 $px8 0 0;
    box-shadow: 0 $px4 $px16 0 $grey-8;

    transition: bottom 1s;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: $px8;

    background-color: $white;
  }

  &__name {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__email {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-area {
    width: $px320;
    height: $px144;
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__submit {
    padding: $px16 $px8;

    border: 0;
    border-radius: $px4;
    outline: none;
    appearance: none;

    background-color: $orange-7;

    color: $white;

    cursor: pointer;

    &:hover {
      background-color: $orange-6;
    }
  }

  &__close-icon {
    width: $px24;
    height: $px24;

    color: $white;

    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  &__envelope-icon {
    width: $px32;
    height: $px32;

    color: $white;
  }

  &--small {
    #{$root}__cta {
      padding: $px8 $px16;
    }

    #{$root}__envelope-icon {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
