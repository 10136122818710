import { getCookie, deleteCookie } from "modules/cookie";

let GTMLoaded = false;

export const trackEventFromCookie = () => {
  const EVENT_COOKIE_NAME = "sqlhe";

  if(getCookie(EVENT_COOKIE_NAME)) {
    try {
      const eventParams = JSON.parse(unescape(getCookie(EVENT_COOKIE_NAME)));

      if(eventParams.event === "signup") {
        trackGTMEvent(eventParams);
      }

      if(eventParams.event === "purchase") {
        trackGTMEvent(eventParams);
      }

      if(eventParams.event === "checkout") {
        trackGTMEvent(eventParams);
      }

      if(eventParams.event === "view_content") {
        trackGTMEvent(eventParams);
      }
    } catch(error) {
      Sentry.captureException(error);
    }

    deleteCookie(EVENT_COOKIE_NAME);
  }
};

export const loadGTM = () => {
  if(GTMLoaded) {
    return;
  }

  // Don't load GTM on the checkout page
  if(document.querySelectorAll(".js-checkout-page, .checkout-page").length > 0) {
    return;
  }

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','gtmDL','GTM-K8LQ85L');

  GTMLoaded = true;

  trackEventFromCookie();
};

export const trackGTMEvent = (params) => {
  if(typeof(window.gtmDL) === "undefined") {
    window.gtmDL = [];
  }

  window.gtmDL.push(params);
};
