<template>
  <div class="search-results">
    <div
      v-if="validResults.length > 0"
      class="search-results__results">
      <a
        :key="item.objectID"
        v-for="item in validResults"
        target="_blank"
        class="search-results__item"
        :href="resultItemUrl(item)">

        <div
          class="search-results__item-name"
          v-html="resultName(item)" />

        <div
          class="search-results__item-chapter"
          v-html="resultChapterName(item)" />
      </a>
    </div>

    <div
      v-if="query && validResults.length === 0"
      class="search-results__no-results">
      Oops, no results found. Don't hesitate <a href="/support">to reach out</a> if you have a question.
    </div>
  </div>
</template>

<script>
import sanitizeHtml from "sanitize-html";

export default {
  name: "SearchResults",
  props: {
    results: {
      type: Array,
      default: () => []
    },
    query: {
      type: String,
      default: null
    }
  },
  computed: {
    validResults() {
      return this.results.filter(item => this.resultItemUrl(item));
    }
  },
  methods: {
    resultChapterName(item) {
      let chapterName;

      if(item._highlightResult && item._highlightResult.name) {
        chapterName = item._highlightResult.chapter_name.value;
      } else {
        chapterName = item.chapter_name;
      }

      return sanitizeHtml(chapterName, { allowedTags: ["mark"] });
    },
    resultItemUrl(item) {
      if(item.objectID.startsWith("lesson_")) {
        return `/lessons/${item.slug}`;
      } else if(item.objectID.startsWith("exercise_")) {
        return `/exercises/${item.slug}`;
      } else if(item.objectID.startsWith("blog_post_")) {
        return `/blog/posts/${item.slug}`;
      } else if(item.objectID.startsWith("mock_interview_exercise_")) {
        return `/practice#${item.slug}`;
      }

      return null;
    },
    resultName(item) {
      let name;

      if(item._highlightResult && item._highlightResult.name) {
        name = item._highlightResult.name.value;
      } else {
        name = item.name;
      }

      return sanitizeHtml(name, { allowedTags: ["mark"] });
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_colors";
@import "design_system/_spacing";
@import "design_system/_grid";
@import "design_system/_typography";
@import "design_system/_animations";

.search-results {
  max-height: 464px;
  overflow-y: scroll;

  &__results-count {
    @include font-small;
    font-style: italic;
  }

  &__item {
    display: block;
    padding: $px8 $px16;

    text-decoration: none;

    mark {
      background-color: $yellow-8;
    }

    &:hover {
      background-color: $blue-3;

      color: $white;

      mark {
        background-color: $blue-3;

        color: $white;
      }
    }

    &:last-child {
      border-radius: 0 0 $px8 $px8;
    }
  }

  &__item-name {
    margin-bottom: $px4;
  }

  &__item-chapter {
    @include font-small;
  }

  &__no-results {
    padding: $px8 $px16;

    @include font-small;
  }
}
</style>
