<template>
  <div class="markdown-cheatsheet">
    <div class="markdown-cheatsheet__description">
      Formatted with
      <strong>
        <a
          href="#"
          class="markdown-cheatsheet__toggle-link js-toggle-markdown-tip"
          @click.prevent="toggleCheatseet">

          Markdown
          <ArrowDown
            :class="'markdown-cheatsheet__toggle-link-icon'"
            v-if="!showCheatsheet" />
          <ArrowUp
            :class="'markdown-cheatsheet__toggle-link-icon'"
            v-else />
        </a>
      </strong>
    </div>

    <div
      v-if="showCheatsheet"
      class="markdown-cheatsheet__cheatsheet">
      <div class="markdown-cheatsheet__cheatsheet-item">
        **bold**<br />
        <strong>bold</strong>
      </div>

      <div class="markdown-cheatsheet__cheatsheet-item">
        *italic*<br />
        <i>italic</i>
      </div>

      <div class="markdown-cheatsheet__cheatsheet-item">
        ~~~pgsql<br />
        SELECT *<br />
        FROM users<br />
        ~~~
        <pre>
          <code class="language-pgsql hljs">
            SELECT *
            FROM users
          </code>
        </pre>
      </div>

      <div class="markdown-cheatsheet__cheatsheet-item">
        * item1<br />
        * item2<br />
        * item3
        <ul>
          <li>item1</li>
          <li>item2</li>
          <li>item3</li>
        </ul>
      </div>

      <div class="markdown-cheatsheet__cheatsheet-item">
        ## Heading h2

        <h2>Heading h2</h2>
      </div>

      <div class="markdown-cheatsheet__cheatsheet-item">
        ## Heading h3

        <h3>Heading h3</h3>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from "font_awesome/angle-down-light.svg";
import ArrowUp from "font_awesome/angle-up-light.svg";

export default {
  components: {
    ArrowDown,
    ArrowUp
  },
  data() {
    return {
      showCheatsheet: false
    }
  },
  methods: {
    toggleCheatseet() {
      this.showCheatsheet = !this.showCheatsheet;
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_breakpoint";
@import "design_system/_colors";
@import "design_system/_grid";
@import "design_system/_spacing";
@import "design_system/_typography";

.markdown-cheatsheet {
  width: 100%;

  &__description {
    display: flex;
  }

  &__toggle-link {
    display: flex;
    align-items: center;
    margin-left: $px4;
  }

  &__toggle-link-icon {
    width: $px16;
    height: $px16;
  }

  &__cheatsheet {
    margin-top: $px16;
    padding: $px16;

    border: $px1 solid $grey-9;
    border-radius: $px4;
  }

  &__cheatsheet-item {
    &:not(:last-child) {
      margin-bottom: $px16;
    }

    pre {
      white-space: pre-line;
    }

    .language-pgsql.hljs {
      padding-bottom: $px16;
    }
  }
}
</style>
