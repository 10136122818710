<template>
  <div class="cookie-consent">
    <div class="cookie-consent__container">
      <div class="cookie-consent__body">
        <h3
          class="cookie-consent__headline"
          v-html="$options.filters.md(`This website uses cookies :cookie:`)" />

        <div
          class="cookie-consent__description"
          v-html="$options.filters.md(`They're used to provide a better UX, enable social sharing, personalize ads and to anonymously analyze website interactions to improve UX. :point_right: <a href='/privacy'>Privacy Policy</a>.`)" />

        <div class="cookie-consent__actions">
          <button
            class="cookie-consent__allow-btn btn btn--block btn--full-width"
            @click.prevent="handleConsent(false)">
            Decline cookies
          </button>

          <button
            class="cookie-consent__allow-btn btn btn--block btn--full-width"
            @click.prevent="handleConsent(true)">
            Allow cookies
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleCookieConsent } from "modules/track-cookie-consent";

export default {
  data() {
    return {
      settingsShown: false
    };
  },
  methods: {
    showSettings() {
      this.settingsShown = true;
    },
    handleConsent(accepted) {
      handleCookieConsent(accepted);

      this.$destroy();

      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_breakpoint";
@import "design_system/_colors";
@import "design_system/_grid";
@import "design_system/_spacing";
@import "design_system/_typography";

.cookie-consent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483650;
  display: flex;

  background-color: rgba($color: $grey-3, $alpha: 0.7);

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    transition: all .25s;
  }

  &__headline {
    display: flex;
    margin: 0 0 $px24;

    .emoji {
      margin-left: $px8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: $px496;
    padding: $px24;

    border-radius: $px8;

    background-color: $grey-10;
  }

  &__description {
    margin-bottom: $px24;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    row-gap: $px8;

    @include breakpoint($md) {
      flex-direction: row;
      column-gap: $px16;
      row-gap: 0;
    }
  }

  &__adjust-settings-link {
    @include font-small;
  }
}
</style>
