<template>
  <div
    class="practice-menu">
    <div class="practice-menu__container">
      <div
        ref="menuItems"
        class="practice-menu__menu-items">
        <div class="practice-menu__status practice-menu__menu-item">
          <StatusMenuItem />
        </div>

        <div class="practice-menu__difficulty practice-menu__menu-item">
          <DifficultyMenuItem />
        </div>

        <div class="practice-menu__dataset practice-menu__menu-item">
          <DatasetMenuItem />
        </div>

        <div class="practice-menu__search practice-menu__menu-item">
          <SearchMenuItem />
        </div>
      </div>

      <div class="practice-menu__active-menu-items">
        <ActiveMenuItems />
      </div>
    </div>
  </div>
</template>

<script>
import customStore from "./custom-store";
import StatusMenuItem from "./menu/status-menu-item";
import DifficultyMenuItem from "./menu/difficutly-menu-item";
import DatasetMenuItem from "./menu/dataset-menu-item";
import SearchMenuItem from "./menu/search-menu-item";
import ActiveMenuItems from "./menu/active-menu-items";

export default {
  name: "PracticeMenu",
  components: {
    StatusMenuItem,
    DifficultyMenuItem,
    DatasetMenuItem,
    SearchMenuItem,
    ActiveMenuItems
  },
  data() {
    return {
      customStore: customStore
    }
  },
  mounted() {
    document.addEventListener("click", e => {
      let targetElement = e.target;

      if(targetElement.classList && targetElement.classList.contains("practice-dropdown-menu__dropdown-menu")) {
        return;
      }

      const $menuItems = this.$refs.menuItems;

      if(!$menuItems) {
        return;
      }

      do {
        if (targetElement == $menuItems) {
          return;
        }

        if(targetElement.classList && targetElement.classList.contains("practice-dropdown-menu__dropdown-menu")) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      this.customStore.closeMenus();
    });
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-menu {
  &__menu-items {
    display: flex;
  }

  &__menu-item {
    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__search {
    flex-grow: 1;
  }

  &__active-menu-items {
    margin-top: $px16;
  }
}
</style>
