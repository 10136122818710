<template>
  <div class="mdn-page-toc">
    <h3 class="mdn-page-toc__headline mb-16">On this page</h3>

    <div>
      <a
        v-for="headline in headlines"
        :key="headline.id"
        :class="itemClass(headline.tag, headline.id)"
        :href="`#${headline.id}`">
        {{ headline.text }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MDNPageTOC",
  props: {
    headlines: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    itemClass(tag, anchor) {
      let result = `mdn-page-toc__item mdn-page-toc__item--${tag}`;

      if (this.activeHeadline === anchor) {
        result = result.concat(" mdn-page-toc__item--active")
      }

      return result;
    }
  },
  data() {
    return {
      activeHeadline: null
    }
  },
  mounted() {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY < this.headlines[0].offsetTop) {
        this.activeHeadline = null
      } else if (window.scrollY > this.headlines[this.headlines.length - 1].offsetTop) {
        this.activeHeadline = this.headlines[this.headlines.length - 1].id
      } else {
        const nextHeadlineIndex = this.headlines.findIndex(h => h.offsetTop > window.scrollY);

        this.activeHeadline = this.headlines[nextHeadlineIndex - 1].id;
      }
    })
  }
}
</script>

<style lang="scss">
@import "design_system/_colors";
@import "design_system/_spacing";
@import "design_system/_grid";
@import "design_system/_typography";
@import "design_system/_animations";

.mdn-page-toc {
  $root: &;

  position: sticky;
  top: $px24;

  h3#{$root}__headline {
    font-size: $px20;
  }

  &__item {
    display: block;
    padding: $px8 $px16;

    background-color: $grey-10;

    font-size: $px16;

    &:hover {
      background-color: $blue-9;

      opacity: 1;
    }

    &:first-child {
      border-radius: $px8 $px8 0 0;
    }

    &:last-child {
      padding-bottom: $px12;

      border-radius: 0 0 $px8 $px8;
    }

    &--h3 {
      padding-left: $px24;
    }

    &--active {
      background-color: $blue-9;
    }
  }
}
</style>
