<template>
  <div class="mark-lesson-as-completed">
    <div class="mark-lesson-as-completed__completed" v-if="lessonCompleted">
      Lesson is finished

      <div class="mark-lesson-as-completed__lesson-status">
        <CheckedIcon :class="'mark-lesson-as-completed__lesson-finished-icon'" />
      </div>
    </div>
    <div class="mark-lesson-as-completed__complete" v-else>
      <button class="mark-lesson-as-completed__cta" @click.prevent="markAsFinished">
        Mark lesson as finished
        <CheckedIcon :class="'mark-lesson-as-completed__finished-icon'" />
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import { confettiBoom } from "modules/confetti-cannon";
import CheckedIcon from "font_awesome/check-light.svg";
import mountContentItemRating from "controllers/content-item-rating";
import { mapGetters } from "vuex";

export default {
  components: {
    CheckedIcon
  },
  data: function () {
    return {
      postUrl: window.state.markAsFinishedUrl
    }
  },
  computed: {
    ...mapGetters("lesson", ["lessonCompleted"]),
  },
  methods: {
    markAsFinished: function() {
      let formData = new FormData();

      axios.post(this.postUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        if(response.data.redirect_to) {
          window.location = response.data.redirect_to;
        }

        let notificationMessage = `Lesson is completed, great job!`;
        const nextContentItem = response.data.next_content_item;

        if(nextContentItem) {
          if(nextContentItem.published) {
            notificationMessage += ` Continue to "<a href="${nextContentItem.path}">${nextContentItem.name}</a>"`;
          } else {
            notificationMessage += ` Next ${nextContentItem.type} "${nextContentItem.name}" will be published soon!`;
          }
        }

        notify({
          message: notificationMessage,
          timeout: false
        });

        confettiBoom();

        mountContentItemRating();

        this.$store.dispatch("lesson/setCompletedFlag", true);

        const $nextContentItemLink = document.querySelector(".content-item-nav__link--next");
        if($nextContentItemLink) {
          $nextContentItemLink.classList.add("content-item-nav__link--highlight");
        }
      }).catch(function (error) {
        Sentry.captureException(error);
      });
    }
  }
}
</script>

<style lang="scss">
  @import "design_system/_breakpoint";
  @import "design_system/_colors";
  @import "design_system/_grid";
  @import "design_system/_spacing";

  .mark-lesson-as-completed {

    &__cta {
      display: flex;
      align-items: center;
      padding: $px16 $px16;

      border: 0;
      border-radius: $px4;
      outline: none;

      background-color: $green-7;

      color: $white;

      cursor: pointer;

      &:hover {
        background-color: $green-9;
      }
    }

    &__finished-icon {
      width: $px24;
      height: $px24;
      margin-left: $px8;

      fill: $white;
    }

    &__completed {
      display: flex;
      align-items: center;
    }

    &__lesson-status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $px32;
      height: $px32;
      margin-left: $px8;

      border-radius: $px16;

      background-color: $green-7;

      color: $white;
    }

    &__lesson-finished-icon {
      width: $px24;
      height: $px24;
    }
  }
</style>
