var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"question-subscription"},[_c('div',{staticClass:"question-subscription__headline"},[_vm._v("\n    EMAIL NOTIFICATIONS\n  ")]),_vm._v(" "),_c('div',{staticClass:"question-subscription__description"},[_vm._v("\n    Subscribe to email notifications about new replies\n  ")]),_vm._v(" "),_c('button',{staticClass:"question-subscription__cta btn btn--sm btn--flex btn--full-width",class:{
      'js-open-signup-popup': _vm.isGuest
    },on:{"click":function($event){$event.preventDefault();return _vm.toggleState.apply(null, arguments)}}},[(_vm.subscribed)?_c('span',{staticClass:"question-subscription__cta-body",class:{
        'js-open-signup-popup': _vm.isGuest
      }},[_c('VolumeOff',{staticClass:"question-subscription__cta-icon",class:{
          'js-open-signup-popup': _vm.isGuest
        }}),_vm._v("\n      UNSUBSCRIBE\n    ")],1):_c('span',{staticClass:"question-subscription__cta-body",class:{
        'js-open-signup-popup': _vm.isGuest
      }},[_c('Volume',{staticClass:"question-subscription__cta-icon",class:{
          'js-open-signup-popup': _vm.isGuest
        }}),_vm._v("\n      SUBSCRIBE\n    ")],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }