<template>
  <div class="js-reply-form" v-if="authenticated">
    <div v-if="isGuest">
      <a
        class="btn btn--sm js-open-signup-popup"
        href="/signup"
        :data-after-auth="currentQuestionPathname">
        Sign up to reply
      </a>
    </div>
    <div v-else>
      <form class="forum-reply-form">
        <div class="forum-reply-form__field" v-if="!username">
          <label for="username">Username</label>
          <input
            type="text"
            v-model="usernameInput"
            placeholder="Username">

          <div class="forum-question-form__field-tip">
            Set&nbsp;<strong>username</strong>&nbsp;for future questions and replies
          </div>
        </div>

        <div class="forum-reply-form__field">
          <textarea
            v-model="body"
            cols="30"
            rows="10"
            class="js-no-menu-mde forum-reply-form__textarea">
          </textarea>

          <div class="forum-reply-form__field-tip">
            <MarkdownCheatsheet />
          </div>
        </div>

        <div class="forum-reply-form__field">
          <div class="forum-reply-form__recaptcha js-recaptcha-checkbox">
          </div>
        </div>

        <div class="forum-reply-form__actions">
          <input
            type="submit"
            class="forum-reply-form__cta btn btn--sm"
            value="SUBMIT REPLY"
            @click.prevent="submitForm">
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SimpleMDE from "simplemde";
import "simplemde/dist/simplemde.min.css";
import axios from "axios";
import { notify } from "modules/notify";
import { mapGetters } from "vuex";
import MarkdownCheatsheet from "components/forum/markdown-cheatsheet";

export default {
  components: { MarkdownCheatsheet },
  store: window.vuexStore,
  data() {
    return {
      body: "",
      usernameInput: "",
      recaptchaSiteKey: window.state.recaptchaSiteKey,
      grecaptchaWidgetId: null,
      simpleMDE: null
    }
  },
  props: ["replyId"],
  computed: {
    ...mapGetters("user", ["isGuest", "loggedIn", "username", "authenticated"]),
    currentQuestionPathname() {
      return window.location.pathname;
    }
  },
  watch: {
    authenticated(newValue, oldValue) {
      if(newValue === true) {
        this.$nextTick(() => {
          this.mountRecaptcha();
          this.mountEditor();
        });
      }
    }
  },
  methods: {
    mountRecaptcha() {
      if(!(this.$el && this.$el.querySelector)) {
        return;
      }

      if(this.grecaptchaWidgetId !== null) {
        return;
      }

      let $recaptchaContainer = this.$el.querySelector(".js-recaptcha-checkbox");

      // Sometimes grecaptcha object doesn't have a render method !!
      if(typeof(grecaptcha) !== "undefined" && grecaptcha.render) {
        this.grecaptchaWidgetId = grecaptcha.render($recaptchaContainer, {
          "sitekey": this.recaptchaSiteKey
        });
      }
    },
    mountEditor() {
      if(!(this.$el && this.$el.querySelector)) {
        return;
      }

      let $textArea = this.$el.querySelector(".js-no-menu-mde");

      if($textArea && !this.simpleMDE) {
        const simplemde = new SimpleMDE({
          element: $textArea,
          indentWithTabs: false,
          autosave: {
            enabled: true,
            delay: 5000,
            uniqueId: `${window.location.pathname}:text-area:${new Date()}`
          },
          toolbar: [],
          status: false
        });

        simplemde.codemirror.on("change", () => {
          this.body = simplemde.value();
        });

        this.simpleMDE = simplemde;
      }
    },
    submitForm() {
      if(this.grecaptchaWidgetId === null) {
        this.mountRecaptcha();
        notify({
          message: "Please, confirm you're human by clicking on the Captcha checkbox. Thank you :heart:",
          type: "alert",
          timeout: false
        });
        return;
      }

      let formData = new FormData();

      if(!this.username) {
        formData.append("username", this.usernameInput);
      }

      formData.append("forum_reply[body]", this.body);
      if(this.replyId) {
        formData.append("forum_reply[parent_id]", this.replyId);
      }
      formData.append("forum_reply[forum_question_id]", window.state.questionId);
      formData.append("g-recaptcha-response", typeof(grecaptcha) !== "undefined" && grecaptcha.getResponse(this.grecaptchaWidgetId));

      axios.post("/forum/replies.json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        if(!this.username) {
          this.$store.dispatch("user/setUsername", this.usernameInput)
        }

        notify({
          message: "Reply was created"
        });

        // destroy the vue listeners, etc
        this.$destroy();

        // remove the element from the DOM
        this.$el.parentNode.removeChild(this.$el);

        window.location.href = response.data.redirect_to;
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  },
  mounted() {
    this.mountRecaptcha();
    this.mountEditor();
  }
}
</script>
