(() => {
  const $exerciseEvaluationPage = document.querySelector(".exercise-evaluation-page");

  if(!$exerciseEvaluationPage) {
    return;
  }

  const $revealSolutionLink = $exerciseEvaluationPage.querySelector(".js-reveal-exercise-solution");
  const $solution = $exerciseEvaluationPage.querySelector(".exercise-evaluation-page__solution");

  if(!$revealSolutionLink || !$solution) {
    return;
  }

  $revealSolutionLink.addEventListener("click", (e) => {
    e.preventDefault();

    $solution.classList.add("exercise-evaluation-page__solution--active");
    $revealSolutionLink.remove();
  })
})();
