<template>
<div
  class="countdown"
  :class="bemModifiers"
  v-if="isVisible">
  <div class="countdown__item font-large">
    Deal ends in
  </div>
  <div class="countdown__item">
    <CountdownDigit
      :value="days"
      :label="$tc('days', days)" />
  </div>
  <div class="countdown__item">
    <CountdownDigit
      :value="hours"
      :label="$tc('hours', hours)" />
  </div>
  <div class="countdown__item">
    <CountdownDigit
      :value="minutes"
      :label="$tc('minutes', minutes)" />
  </div>
  <div class="countdown__item">
    <CountdownDigit
      :value="seconds"
      :label="$tc('seconds', seconds)" />
  </div>
</div>
</template>

<script>
import CountdownDigit from "./countdown-digits";

export default {
  components: {
    CountdownDigit
  },
  data() {
    return {
      isVisible: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      endDate: new Date(this.endDateInput).getTime()
    };
  },
  props: ["endDateInput", "modifiers"],
  computed: {
    bemModifiers() {
      return this.modifiers.map(m => {
        return `countdown--${m}`
      }).join(" ");
    }
  },
  mounted() {
    let countdownInterval = setInterval(() => {
      let now = new Date().getTime();

      let distance = this.endDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if(distance > 0) {
        this.isVisible = true;
      } else {
        clearInterval(countdownInterval);
        this.isVisible = false;
      }
    }, 1000);
  },
  i18n: {
    messages: {
      en: {
        days: "day | days",
        hours: "hour | hours",
        minutes: "minute | minutes",
        seconds: "second | seconds"
      }
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_breakpoint";
@import "design_system/_colors";
@import "design_system/_grid";
@import "design_system/_spacing";
@import "design_system/_typography";

.countdown {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    &:not(:last-child) {
      margin-right: $px8;
    }
  }

  @include breakpoint($md) {
    justify-content: baseline;
  }
}
</style>
