import Vue from "modules/vue_twemoji";
import JobAdBanner from "components/job-board/job-ad-banner";
import axios from "axios";

const $wrapper = document.querySelector(".js-job-ad");

if($wrapper) {
  axios.get(`/api/job_board/jobs/banner.json`, {}).then(response => {
    if(response.data.job_ad) {
      let $container = document.createElement("div");
      $wrapper.appendChild($container);

      new Vue({
        el: $container,
        render: h => h(JobAdBanner, {
          props: {
            job: response.data.job_ad
          }
        })
      });

      $wrapper.classList.add("active");
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
