import axios from "axios";
import { notify } from "modules/notify";
import { confettiBoom } from "modules/confetti-cannon";
import Vue from "vue";
import ContentItemRating from "components/content-item-rating.vue";

const mountExerciseForm = () => {
  const $exeriseForm = document.querySelector(".exercise-form");

  if(!$exeriseForm) {
    return;
  }

  $exeriseForm.addEventListener("submit", function(e) {
    e.preventDefault();

    const $submitButton = $exeriseForm.querySelector(".exercise-form__submit");
    $submitButton.value = "Verifying your answer...";
    $submitButton.disabled = true;

    let formData = new FormData();

    formData.set(
      "answer",
      $exeriseForm.querySelector(".exercise-form__answer").value
    );

    axios.post(this.action + ".json", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then(response => {
      if(response.data.redirect_to) {
        window.location = response.data.redirect_to;
      }

      let notificationMessage = `That was the right answer, yay!`;
      const nextContentItem = response.data.next_content_item;

      if(nextContentItem) {
        if(nextContentItem.published) {
          notificationMessage += ` Continue to "<a href="${nextContentItem.path}">${nextContentItem.name}</a>"`;
        } else {
          notificationMessage += ` Next ${nextContentItem.type} "${nextContentItem.name}" will be published soon!`;
        }
      }

      notify({
        message: notificationMessage,
        timeout: false
      });
      confettiBoom();

      this.completed = true;

      if(document.querySelector("#content-item-rating")) {
        new Vue({
          el: "#content-item-rating",
          render: h => h(ContentItemRating)
        });
      }

      const $nextContentItemLink = document.querySelector(".content-item-nav__link--next");
      if($nextContentItemLink) {
        $nextContentItemLink.classList.add("content-item-nav__link--highlight");
      }
    }).catch(function(error) {
      if(error.response.data.incorrectAnswer) {
        notify({
          message: "Answer is incorrect, try again",
          type: "alert"
        });

        if(error.response.data.showEditorHint) {
          let $editorMenuTab = document.querySelector(".tabs-menu__button[data-tab='sql_editor']");

          if($editorMenuTab) {
            $editorMenuTab.classList.add("tabs-menu__button--highlight");
          }

          let $editorHint = $exeriseForm.querySelector(".exercise-form__editor-hint");
          $editorHint.classList.add("exercise-form__editor-hint--active");
        }
      } else {
        notify({
          message: "Something went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      }

    }).finally(function() {
      const $submitButton = $exeriseForm.querySelector(".exercise-form__submit");
      $submitButton.value = "Submit the answer";
      $submitButton.disabled = false;
    });
  });

  $exeriseForm.addEventListener("keydown", function(e) {
    if (!((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 13)) {
      return;
    }

    $exeriseForm.submit();
  });

  const $submitButton = $exeriseForm.querySelector(".exercise-form__submit");
  $submitButton.disabled = false;
};

export default mountExerciseForm;
