import Vue from "modules/vue_twemoji";
import axios from "axios";
import UpvoteQuestionButton from "components/forum/upvote-question-button";
import { store } from "store";

const $upvoteButtons = document.querySelectorAll(".js-upvote-question-button");

if($upvoteButtons.length > 0) {
  let questionIds = Array.from($upvoteButtons).map(b => b.dataset.questionId);

  axios.get("/forum/questions/upvote_counts", {
    params: {
      question_ids: questionIds
    },
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
    }
  }).then(response => {
    $upvoteButtons.forEach($upvoteButton => {
      let questionId = parseInt($upvoteButton.dataset.questionId, 10);

      new Vue({
        el: $upvoteButton,
        store,
        render: h => h(UpvoteQuestionButton, {
          props: {
            upvotesCount: parseInt($upvoteButton.dataset.upvotesCount, 10),
            questionId: questionId,
            userCanUpvote: response.data.upvotedQuestionIds && !response.data.upvotedQuestionIds.includes(questionId)
          }
        })
      });
    });
  }).catch(function (error) {
    console.warn(error);

    Sentry.captureException(error);
  });
}
