<template>
  <div
    class="db-compatibility-drawer"
    :class="{
      'db-compatibility-drawer--active': drawerActive,
      [`db-compatibility-drawer--${placement}`]: true
    }">
    <div>
      <div
        v-if="compatibilityParams.min_version === 0"
        class="db-compatibility-drawer__status flex items-center">
        <CheckCircleSolidIcon
          :class="'db-compatibility-drawer__check-icon w-16 h-16 mr-8'"/>
        <span>
          Full support in all DB versions
        </span>
      </div>

      <div
        v-else-if="typeof(compatibilityParams.min_version) === 'number' && compatibilityParams.min_version > 0"
        class="db-compatibility-drawer__status flex items-center">
        <CheckCircleSolidIcon
          :class="'db-compatibility-drawer__check-icon w-16 h-16 mr-8'"/>
        <span>
          Full support since version {{ compatibilityParams.min_version }} (released {{ compatibilityParams.min_version_released_at }})
        </span>
      </div>

      <div
        v-else
        class="db-compatibility-drawer__status flex items-center">
        <TimesCircleSolidIcon
          :class="'db-compatibility-drawer__times-icon w-16 h-16 mr-8'"/>
        <span>
          Full support in all DB versions
        </span>
      </div>
    </div>

    <div
      v-if="note"
      class="db-compatibility-drawer__note"
      v-html="$options.filters.md(note)" />

    <div
      class="db-compatibility-drawer__related-pages"
      v-if="showRelatedPages">
      <h3 class="mb-16">Related pages</h3>

      <a
        v-for="page in compatibilityParams.related_pages"
        class="block"
        :key="page.url"
        :href="page.url"
        v-html="$options.filters.md(`:link: ${page.name}`)" />
    </div>
  </div>
</template>

<script>
import CheckCircleSolidIcon from "font_awesome/check-circle-solid.svg";
import TimesCircleSolidIcon from "font_awesome/times-circle-solid.svg";

export default {
  name: "DatabaseCompatibilityDrawer",
  components: {
    CheckCircleSolidIcon,
    TimesCircleSolidIcon
  },
  data() {
    return {
      compatibilityParams: {}
    }
  },
  props: ["placement"],
  computed: {
    drawerActive() {
      return Object.keys(this.compatibilityParams).length > 0;
    },
    showRelatedPages() {
      return this.compatibilityParams &&
        this.compatibilityParams.related_pages &&
        Object.keys(this.compatibilityParams.related_pages).length > 0;
    },
    note() {
      return this.compatibilityParams &&
        this.compatibilityParams.note
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";

.db-compatibility-drawer {
  $root: &;

  display: hidden;
  height: auto;

  &__status {
    overflow-wrap: break-word;
  }

  &__check-icon {
    color: $green-5;
  }

  &__times-icon {
    color: $red-5;
  }

  &__note {
    margin-top: $px16;
  }

  &__related-pages {
    margin-top: $px16;
  }

  &--active {
    display: flex;
    flex-direction: column;
  }

  &--mobile {
    #{$root}__status {
      padding: $px16;
    }

    #{$root}__note {
      margin-top: 0;
      padding: $px16;
    }

    #{$root}__related-pages {
      margin-top: 0;
      padding: $px16;
    }
  }
}
</style>
