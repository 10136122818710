<template>
  <div :class="notificationClass">

    <div class="notification__container">
      <div class="notification__message" v-html="$options.filters.md(message)" />

      <CloseIcon :class="'notification__close'" @click="closeNotification" />
    </div>
  </div>
</template>

<script>
import CloseIcon from "font_awesome/x-light.svg";

export default {
  props: {
    message: String,
    type: {
      type: String,
      default: "success"
    },
    timeout: {
      type: Boolean,
      default: false
    },
    enableSticky: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    notificationClass() {
      let cssClass = `notification notification--${this.type} no-print`;

      if(this.enableSticky && this.sticky) {
        cssClass += " notification--sticky"
      }

      return cssClass;
    }
  },
  data() {
    return {
      sticky: false
    }
  },
  methods: {
    closeNotification() {
      this.$destroy();

      // remove the element from the DOM
      if(this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  },
  components: {
    CloseIcon
  },
  mounted() {
    if(this.timeout) {
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    }

    const handleSticky = () => {
      if (window.pageYOffset > 0) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }

    window.addEventListener("scroll", () => {
      handleSticky();
    });

    handleSticky();
  }
}
</script>

<style lang="scss">
@keyframes negative-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes positive-shake {
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.08);
  }

  100% {
    transform: scale(1.0);
  }
}

@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_grid";

.notification {
  width: 100%;
  padding: $px24 0;

  background-color: $green-7;

  color: $white;

  backface-visibility: hidden;

  &__container {
    @include container;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__message {
    a {
      color: inherit;
    }
  }

  &__close {
    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  &--alert {
    background-color: $red-5;

    animation: negative-shake 1s cubic-bezier(.36, .07, .19, .97) both;
  }

  &--success {
    background-color: $green-7;

    animation: positive-shake 1s cubic-bezier(.36, .07, .19, .97) both;
  }

  &--warning {
    background-color: $yellow-7;

    color: $grey-1;

    animation: positive-shake 1s cubic-bezier(.36, .07, .19, .97) both;
  }

  &--sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483649; // Navbar + 2
  }
}
</style>
