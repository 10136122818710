<template>
  <div
    class="practice-exercise"
    :id="exercise.slug"
    :data-id="exercise.id">

    <div class="practice-exercise__container">
      <h3 class="practice-exercise__name">
        {{ exercise.position }}. {{ exercise.name }}

        <div
          class="practice-exercise__status"
          :class="{
            'practice-exercise__status--active': exercise.solved
          }">
          <CheckIcon
            class="practice-exercise__status-icon" />
        </div>
      </h3>

      <div class="practice-exercise__info">
        <div class="practice-exercise__info-badge">
          <HotPepperIcon
            :key="n"
            v-for="n in exercise.difficulty"
            class="practice-exercise__difficutly-icon" />
        </div>

        <div class="practice-exercise__info-badge">
          <DatabaseIcon
            class="practice-exercise__info-badge-icon" />
          {{ exercise.dataset_name }}
        </div>
      </div>

      <div
        class="practice-exercise__summary"
        v-html="exercise.summary">
      </div>

      <div class="practice-exercise__footer">
        <div class="practice-exercise__actions">
          <a
            class="practice-exercise__cta btn btn--flex"
            :class="{
              'js-open-signup-popup': isGuest
            }"
            :href="ctaUrl"
            v-if="exerciseAvailable">
            Start exercise
          </a>
          <a
            class="practice-exercise__cta btn btn--flex"
            :class="{
              'js-open-signup-popup': isGuest
            }"
            :href="ctaUrl"
            v-else>
            <LockIcon class="practice-exercise__cta-icon"/>
              Buy full access
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "font_awesome/check-light.svg";
import HotPepperIcon from "twemoji/hot-pepper.svg";
import DatabaseIcon from "font_awesome/database-light.svg";
import LockIcon from "font_awesome/lock-alt-light.svg";
import customStore from "./custom-store";
import { mapGetters } from "vuex";

export default {
  name: "PracticeExercise",
  components: {
    CheckIcon,
    DatabaseIcon,
    HotPepperIcon,
    LockIcon
  },
  props: {
    exercise: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customStore: customStore
    }
  },
  computed: {
    ...mapGetters("user", ["isGuest", "segment"]),
    exerciseAvailable() {
      return this.segment === "customer" || this.exercise.free;
    },
    ctaUrl() {
      if(this.isGuest) {
        if(this.exerciseAvailable) {
          return `/signup`;
        } else {
          return "/pricing";
        }
      } else {
        if(this.exerciseAvailable) {
          return `/mock-interviews/exercises/${this.exercise.slug}/practice`;
        } else {
          return "/pricing";
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-exercise {
  position: relative;
  padding: $px24;

  border: $px1 solid $grey-9;
  border-radius: $px4;

  &__name {
    display: flex;
    align-items: center;
    margin: 0 0 $px8;
  }

  &__summary {
    margin-bottom: $px16;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: $px16;

    @include font-small;
  }

  &__info-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $grey-9;

    &:not(:last-child) {
      margin-right: $px8;
    }
  }

  &__info-badge-icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;
    flex-shrink: 0;
  }

  &__status {
    display: none;
    position: absolute;
    top: -$px1;
    right: -$px1;
    width: $px32;
    height: $px32;
    align-items: center;
    justify-content: center;
    margin-left: $px8;
    flex-shrink: 0;

    border-radius: 0 $px4 0 $px4;

    background-color: $green-7;

    color: $white;

    &--active {
      display: flex;
    }
  }

  &__status-icon {
    width: $px16;
    height: $px16;
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cta-icon {
    width: $px16;
    height: $px16;
    margin-right: $px8;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__difficutly-icon {
    width: $px16;
    height: $px16;
  }
}
</style>
