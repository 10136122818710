<template>
  <div class="practice-exercises">
    <div class="practice-exercises__menu">
      <Menu />
    </div>

    <div class="practice-exercises__exercises">
      <div
        :key="exercise.slug"
        v-for="exercise in visibleExercises"
        class="practice-exercises__exercise">
        <Exercise
          :exercise="exercise" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "./menu";
import Exercise from "./exercise";
import customStore from "./custom-store";
import { mapGetters } from "vuex";

export default {
  name: "PracticeExercises",
  components: {
    Exercise,
    Menu
  },
  computed: {
    ...mapGetters("user", ["segment"]),
    visibleExercises() {
      return this.customStore.state.exercises.filter(exercise => {
        return this.customStore.exerciseVisible(exercise);
      });
    }
  },
  data() {
    return {
      customStore: customStore,
      activeMenu: null
    }
  },
  mounted() {
    axios
      .get("/api/mock-interviews/exercises")
      .then(response => {
        const exercises = JSON.parse(response.data.exercises);
        const solvedExercisesIds = response.data.solved_exercises_ids;

        this.customStore.setExercises(exercises);

        if (solvedExercisesIds) {
          this.customStore.state.exercises.forEach(exercise => {
            // https://medium.com/@miladmeidanshahi/update-array-and-object-in-vuejs-a283983fe5ba
            this.$set(exercise, "solved", solvedExercisesIds.indexOf(exercise.id) > -1)
          });
        }

        const $spinner = document.querySelector(".mock-interviews-exercises-page__spinner");
        if($spinner) {
          $spinner.remove();
        }
      })
      .catch(error => {
        Sentry.captureException(error)
      });
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";
@import "design_system/_grid";

.practice-exercises {
  &__menu {
    display: none;

    @include breakpoint($md) {
      display: block;
      margin-bottom: $px16;
    }
  }

  &__exercise {
    &:not(:last-child) {
      margin-bottom: $px24;
    }
  }
}
</style>
