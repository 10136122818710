<template>
  <div class="tooltip">
    <InfoIcon class="tooltip__icon" />

    <div class="tooltip__copy">
      {{ copy }}
    </div>
  </div>
</template>

<script>
import InfoIcon from "font_awesome/info-circle-light.svg";

export default {
  name: "Tooltip",
  components: {
    InfoIcon
  },
  props: ["copy"]
}
</script>

<style lang="scss">
@import "design_system/_breakpoint";
@import "design_system/_colors";
@import "design_system/_grid";
@import "design_system/_spacing";
@import "design_system/_typography";

.tooltip {
  $root: &;

  display: inline-block;
  position: relative;

  &__icon {
    width: $px16;
    height: $px16;
  }

  &__copy {
    width: $px240;
    padding: $px8;
    position: absolute;
    top: -$px12;
    left: 50%;
    visibility: hidden;
    z-index: 1;
    opacity: .40;

    border-radius: $px4;

    background: $grey-3;

    color: $white;
    @include font-small;

    transform: translate(-50%, -100%);
    transition: all 0.25s ease-in;

    box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);

    &:after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      width: 0;
      height: 0;

      border-style: solid;
      border-width: 7px 7.5px 0 7.5px;
      border-color: $grey-3 transparent transparent transparent;

      content: " ";
    }
  }

  &:hover {
    #{$root}__copy {
      visibility: visible;

      opacity: 1;
      transition: .25s linear;
    }
  }
}
</style>
