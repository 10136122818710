import Vue from "modules/vue_twemoji";
import JobAdForm from "components/job-board/job-ad-form";
import axios from "axios";

const $container = document.querySelector(".js-job-ad-form");

if($container) {
  axios.get("/api/prices.json", {
    params: {
      slugs: ["job_ad"]
    }
  }).then(response => {
    const products = response.data.products;
    const jobProduct = products.find(p => p.slug === "job_ad");

    new Vue({
      el: $container,
      render: h => h(JobAdForm, {
        props: {
          product: jobProduct
        }
      })
    });
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
