<template>
  <div
    @click.prevent="toggleFilter()"
    class="practice-dropdown-menu-item"
    :class="{
      'practice-dropdown-menu-item--active': customStore.filterActive(value)
    }">
    {{ name }}
  </div>
</template>

<script>
import customStore from "../custom-store";

export default {
  name: "PracticeDropdownMenuItem",
  props: ["name", "value"],
  data() {
    return {
      customStore: customStore
    }
  },
  methods: {
    toggleFilter() {
      this.customStore.toggleFilter(this, this.name, this.value);
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-dropdown-menu-item {
  padding: $px8;

  border-radius: $px4;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $px4;
  }

  &:hover {
    background-color: $grey-10;;
  }

  &--active {
    background-color: $grey-8;

    &:hover {
      background-color: $grey-8;
    }
  }
}
</style>
