import Vue from "modules/vue_twemoji";
import ProductPrice from "components/product-price";
import PromoBanner from "components/promo-banner";
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";

setCouponCookie();

const $productPrices = document.querySelectorAll(".js-product-price");
const slugs = Array.from($productPrices).map(el => el.dataset.slug);
const urlParams = new URLSearchParams(window.location.search);

if(slugs.length > 0) {
  axios.get("/api/prices.json", {
    params: {
      slugs: slugs,
      pr: urlParams.get("ref")
    }
  }).then(response => {
    const products = response.data.products;

    $productPrices.forEach($priceContainer => {
      let productSlug = $priceContainer.dataset.slug;

      if(response.data.personalProductSlug === "course_student" && productSlug === "course_personal") {
        productSlug = "course_student";
      }

      const product = products.find(p => p.slug === productSlug);
      const modifiers = String($priceContainer.dataset.modifiers).split(",");

      new Vue({
        el: $priceContainer,
        render: h => h(ProductPrice, {
          props: {
            product,
            modifiers
          }
        })
      });
    });

    const $productPurchaseCtas = document.querySelectorAll(".js-product-purchase-cta");
    $productPurchaseCtas.forEach($purchaseCta => {
      let productSlug = $purchaseCta.dataset.slug;

      if(!productSlug) {
        return;
      }

      if(response.data.personalProductSlug === "course_student" && productSlug === "course_personal") {
        productSlug = "course_student";
      }

      const product = products.find(p => p.slug === productSlug);
      $purchaseCta.href = product.purchaseHref;
      $purchaseCta.dataset.afterAuth = product.purchaseHref;
    });

    if(response.data.promo_banner) {
      const $container = document.querySelector(".vue-promo-banner-container");

      new Vue({
        el: $container,
        render: h => h(PromoBanner, {
          props: response.data.promo_banner
        })
      });
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
