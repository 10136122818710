import { render, staticRenderFns } from "./bookmark.vue?vue&type=template&id=474db98d&"
import script from "./bookmark.vue?vue&type=script&lang=js&"
export * from "./bookmark.vue?vue&type=script&lang=js&"
import style0 from "./bookmark.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports