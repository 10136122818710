import SimpleMDE from "simplemde";
import "simplemde/dist/simplemde.min.css";
import axios from "axios";

document.querySelectorAll(".js-no-menu-mde").forEach(($textArea, textAreaIndex) => {
  const insertPgsql = (editor) => {
    const cm = editor.codemirror;

    const doc = cm.getDoc();
    const cursor = doc.getCursor();

    cm.replaceSelection("~~~pgsql\n\n~~~");

    cm.focus();

    cm.setCursor(cursor.line + 1, 0);
  };

  let simplemde = new SimpleMDE({
    element: $textArea,
    indentWithTabs: false,
    autosave: {
      enabled: true,
      delay: 5000,
      uniqueId: `${window.location.pathname}:text-area:${textAreaIndex}`
    },
    toolbar: [
      {
        name: "insertPgsql",
        action: insertPgsql,
        className: "fa fa-code",
        title: "Insert PGSql"
      },
      "bold",
      "italic",
      "quote",
      "heading-2",
      "heading-3",
      "unordered-list",
      "image",
      "table"
    ],
    status: false
  });
});
