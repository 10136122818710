<template>
  <div
    class="features-nav"
    :class="{ 'features-nav--reversed': this.reversed }"
  >
    <div :class="navItemClass(index)" v-for="(image, index) in images"
         :key="index"
         @click="activateImage(index)">
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";

export default {
  methods: {
    navItemClass(image) {
      if(image === this.activeImage) {
        return "features-nav__item features-nav__item--active";
      } else {
        return "features-nav__item";
      }
    },
    activateImage: function(index) {
      this.activeImage = index;

      let $activeFeature = this.featuresScreenshotBlock.querySelector(".features-screenshots__feature--active");
      $activeFeature.classList.remove("features-screenshots__feature--active");

      let $newActiveFeature = this.featuresScreenshotBlock.querySelectorAll(".features-screenshots__feature")[index];
      $newActiveFeature.classList.add("features-screenshots__feature--active");
    },
    nextImage: function() {
      this.activeImage = (this.activeImage + 1) % this.images.length;
    },
    prevImage: function() {
      this.activeImage = (this.activeImage + this.images.length - 1) % this.images.length;
    }
  },
  data() {
    this.$i18n.locale = "en";

    return {
      locale: "en",
      activeImage: 0
    }
  },
  props: ["featuresScreenshotBlock", "reversed", "images"],
  mounted() {
    this.featuresScreenshotBlock.classList.add("features-screenshots--active");

    let $leftArrow = this.featuresScreenshotBlock.querySelector(".js-features-nav-left");

    $leftArrow.addEventListener("click", e => {
      e.preventDefault();

      this.prevImage();
      this.activateImage(this.activeImage);
    });

    let $rightArrow = this.featuresScreenshotBlock.querySelector(".js-features-nav-right");

    $rightArrow.addEventListener("click", e => {
      e.preventDefault();

      this.nextImage();
      this.activateImage(this.activeImage);
    });


    const manager = new Hammer.Manager(this.featuresScreenshotBlock, {
      recognizers: [
        [Hammer.Swipe,{ direction: Hammer.DIRECTION_HORIZONTAL }]
      ]
    });

    let deltaX = 0;
    let deltaY = 0;

    manager.on("swipe", (e) => {
      try {
        deltaX = deltaX + e.deltaX;
        let direction = e.offsetDirection;

        if (direction === 4) {
          this.prevImage();
          this.activateImage(this.activeImage);
        }

        if (direction === 2) {
          this.nextImage();
          this.activateImage(this.activeImage);
        }
      } catch(error) {
        Sentry.captureException(error);
      }
    });
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.features-nav {
  display: flex;
  align-items: center;

  &__item {
    width: $px12;
    height: $px12;

    border-radius: 50%;

    background-color: $grey-6;

    @include font-small;

    cursor: pointer;

    &--active {
      background-color: $blue-6;

      color: $white;
    }

    &:not(:last-child) {
      margin-right: $px8;
    }
  }

  &--reversed {
    justify-content: flex-end;
  }
}
</style>
