<template>
  <div
    class="practice-dropdown-menu"
    :class="{
      'practice-dropdown-menu--active': active
    }">
    <div
      class="practice-dropdown-menu__container"
      @click.prevent="toggle">
      <div class="practice-dropdown-menu__name">
        {{ name }}
      </div>

      <DownArrowIcon
        class="practice-dropdown-menu__dropdown-icon" />
    </div>

    <div
      v-if="active"
      class="practice-dropdown-menu__dropdown-menu">
      <slot />
    </div>
  </div>
</template>

<script>
import customStore from "../custom-store";
import DownArrowIcon from "font_awesome/angle-down-light.svg";

export default {
  name: "PracticeDropdownMenu",
  components: {
    DownArrowIcon
  },
  props: ["name"],
  data() {
    return {
      customStore: customStore
    }
  },
  computed: {
    active() {
      return this.customStore.state.activeMenuItem === this.name;
    }
  },
  methods: {
    toggle() {
      if(this.active) {
        this.customStore.closeMenus();
      } else {
        this.customStore.activateMenuItem(this.name);
      }
    }
  }
}
</script>

<style lang="scss">
@import "design_system/_spacing";
@import "design_system/_colors";
@import "design_system/_typography";

.practice-dropdown-menu {
  $root: &;

  position: relative;

  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;
    padding: $px8;

    border-radius: $px4;

    background-color: $grey-10;
  }

  &__name {
    margin-right: $px8;
  }

  &__dropdown-icon {
    width: $px24;
    height: $px24;

    transition: transform .3s linear;
  }

  &__dropdown-menu {
    display: none;
  }

  &--active {
    #{$root}__dropdown-icon {
      transform: rotate(180deg);
    }

    #{$root}__dropdown-menu {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      margin-top: $px8;
      width: $px240;
      padding: $px8;

      border-radius: $px4;
      box-shadow: 0 $px4 $px16 0 $grey-8;

      background-color: $white;
    }
  }
}
</style>
