<template>
  <div class="content-item-rating" v-if="active">
    <div class="content-item-rating__rating" v-if="!rating.persisted">
      <ThumbsRating :contentItem="contentItem" :rating="rating" @thumbsRatingSaved="onThumbsRatingSaved" />
    </div>

    <div class="content-item-rating__feedback" v-if="rating.persisted && !rating.feedbackPresent">
      <FeedbackRating :contentItem="contentItem" :rating="rating" @feedbackRatingSaved="onFeedbackRatingSaved"/>
    </div>
  </div>
</template>

<script>
import ThumbsRating from "components/thumbs-rating";
import FeedbackRating from "components/feedback-rating";
import { mapGetters } from "vuex";

export default {
  components: {
    ThumbsRating,
    FeedbackRating
  },
  data: function () {
    return {
      contentItem: window.state.contentItem,
      rating: null
    }
  },
  props: ["inputRating"],
  computed: {
    ...mapGetters("lesson", ["lessonCompleted"]),
    active() {
      if(!this.lessonCompleted) {
        return false;
      }

      if(!this.rating) {
        return false;
      }

      return this.rating.addFeedbackUrl && !this.rating.feedbackPresent;
    }
  },
  methods: {
    onFeedbackRatingSaved: function() {
      this.$destroy();

      this.$el.parentNode.removeChild(this.$el);
    },
    onThumbsRatingSaved: function(contentItemRatingId, ratingValue) {
      this.$set(this.rating, "persisted", true);
      this.$set(this.rating, "id", contentItemRatingId);
      this.$set(this.rating, "value", ratingValue);
    }
  },
  mounted() {
    this.rating = this.inputRating;
  }
};
</script>

<style lang="scss">
  @import "design_system/_breakpoint";
  @import "design_system/_colors";
  @import "design_system/_grid";
  @import "design_system/_spacing";

  .content-item-rating {
  }
</style>
