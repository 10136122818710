import Vue from "vue/dist/vue.esm";
import twemoji from "twemoji";

let md = require("markdown-it")({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true
});
let emoji = require("markdown-it-emoji");

md.use(emoji);

md.renderer.rules.emoji = function(token, idx) {
  return twemoji.parse(token[idx].content, {
    base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
  });
};

let stats = window.stats || {};
Vue.filter("md", value => {
  for (const key in stats) {
    value = value.replace(`%{${key}}`, stats[key]);
  }

  return md.renderInline(value);
});

Vue.filter("mdWithP", function (value) {
  return md.render(value);
});

export default Vue;
