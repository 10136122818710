const getters = {
  isGuest(state) {
    return state.isGuest;
  },
  loggedIn(state) {
    return state.authToken && !state.isGuest;
  },
  authenticated(state) {
    return !!state.authToken;
  },
  authToken(state) {
    return state.authToken;
  },
  username(state) {
    return state.username;
  },
  segment(state) {
    return state.segment;
  }
};

export default getters;
